import React from "react"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "../utils/breakpoints/"
import { colors } from "../utils/variables/"

// Components
import Container from "./container"
import { ExternalButtonLink } from "./buttons"

const StyledContainer = styled(Container)`
  max-width: 1150px;
  overflow: hidden;
  padding: 0px;
`

const StyledBodyLanding = styled.section`
  display: flex;
  flex-wrap: wrap;
  padding: 40px 10px;
  column-gap: 30px;
  text-align: center;

  ${breakpoint.small`
    flex-wrap: nowrap;
    padding: 180px 20px 40px;
	text-align: left;
  `}

  ${breakpoint.medium`
    flex-wrap: nowrap;
    padding: 120px 20px 40px;
  `}

  .block {
    width: 100%;

    ${breakpoint.small`
      width: 100%;
    `}

    &.block--white {
      display: flex;
    }

    &.block--image {
		max-width: 300px;
		width: 100%;
		margin: 0 auto;
		padding-top: 50px;

		${breakpoint.medium`
		padding-top: 250px;
	  `}

		.side_image  {
			width: 300px;
			height: 300px;
			border: 2px solid ${colors.purpleDark};
			padding: 20px 0;

			${breakpoint.medium`
				width: 250px;
				height: 250px;
		  	`}
	
		}
			ul {
				display: inline-block;
			}

			.line {
				display: block;
				margin: 0 auto;
			}

			ul li:first-child .line {
			  display: none;
			}

			.line {
				 border-left: 3px solid ${colors.purpleDark};
				 height: 100px;
				 margin: 0 50%;
			}
	 }

	  .feature__image {
	  	max-width: 550px!important;
		position: relative;
	  	margin: 0px auto!important;
	  
  		${breakpoint.small`
			margin: -360px auto!important;
			position: absolute !important;
		`}

  		${breakpoint.medium`
			margin: -420px auto!important;
			width: 550px;
			position: absolute !important;
		`}

	  }
  
  .lower {
  		${breakpoint.small`
			margin: -380px auto;
			width: 360px;
			position: absolute !important;
		`}

  		${breakpoint.medium`
			margin: -380px auto;
			width: 420px;
			position: absolute !important;
		`}
  }


    .block__content, .block__content_thankyou {
    	padding-top: 0;
   
     ${breakpoint.small`
	 	padding-top: 50px;		
	  `}
 
		h2 {
			font-size: 26px;
			margin: 1rem 2rem;
			text-align: center;

			${breakpoint.small`
				font-size: 36px;
				text-align: left;
				padding-top: 50px;		
				margin: 1rem 0;
				`}
		}

		h3 {
		  margin-top: 40px;
		  margin-bottom: 10px;
		  text-align: center;
  
		${breakpoint.small`
			text-align: left;
		`}
		}

		p {
			font-size: 19px;
			line-height: 1.6em;
			width: 90%;
			text-align: center;
			padding-inline: 20px;

		${breakpoint.small`
			width: 85%;
			padding-inline: 0;
			text-align: left;
		`}

		}

	  .note {
	  	margin-top: 20px;
		font-size: 12px;
		}
	  
    }
    
    .block__content_thankyou {
		 ${breakpoint.small`
			padding-top: 150px;
		  `}
		 ${breakpoint.medium`
			padding-top: 200px;
		  `}
    	}

    .buttonLink {
    	margin: 20px auto 0;

		 ${breakpoint.small`
			margin: 30px 0 0;
		  `}
    }

    ${ExternalButtonLink} {
		width: auto;
		}
    
  }
`


const BodyLanding = props => {
	return (
		<StyledContainer>
			<StyledBodyLanding>
				<div className="block block--white">
					<div className="block__content">
						<StaticImage
							src="./../assets/images/request-a-demo/visby-sti-device.png"
							width={550}
							alt="Visby Medical Sexual Health Test"
							className="feature__image"
							data-aos="fade-up"
						/>
						<h2 data-aos="fade-up">Improve patient care with the Visby&nbsp;Medical Sexual Health Test</h2>

						{props.landingPage === "minutes-matter" ?
							(
								<p data-aos="fade-up">As a clinician, rapid triage, diagnosis, treatment and patient disposition is at the center of your emergency department. Timely test results and appropriate
									treatment in one visit gives your patients peace of mind so they can focus on taking care of themselves, their partners and families.
									Holistic approach. Outsized medical benefits.</p>
							)
							:
							(
								<p data-aos="fade-up">As a clinician, the well-being of your patients is at the center of your practice. Timely test results and appropriate
									treatment in one visit gives your patients peace of mind so they can focus on taking care of themselves, their partners and families.
									Holistic approach. Outsized medical benefits.</p>
							)
						}

						<h3 data-aos="fade-up">Bring the lab to you</h3>
						<p data-aos="fade-up">The Visby Medical Sexual Health Test is as accurate as lab-based PCR systems, with greater than 97% accuracy.
							Upgrade the diagnostics you are providing to patients without sacrificing on accuracy. </p>

						<h3 data-aos="fade-up">A new stream of revenue</h3>
						<p data-aos="fade-up">The Visby Medical Sexual Health Test offers significant cost reduction over conventional PCR testing.
							Unlike a large instrument with costly up-front and maintenance costs, the instrument-free Visby Test allows you to test at the
							point of care instead of sending samples out to the lab. </p>

						<h3 data-aos="fade-up">Easy integration</h3>
						<p data-aos="fade-up">Designed in collaboration with thought leaders in the medical profession, the Visby Test is both easy to use,
							and easy to deploy into your practice. The ultra compact, single use device saves clinician time by eliminating the need for
							batching samples for send out, and then spending time contacting patients for follow-up. The Visby Test is instrument-free which
							eliminates the maintenance and calibration burden associated with other PCR systems. </p>

						<h3 data-aos="fade-up">Informed treatment every time</h3>
						<p data-aos="fade-up">Results available during the patient's visit help eliminate the uncertainty of empirical medicine. Prescribe your
							patient and their partner* the correct antibiotic in the same visit, which may lead to a quicker recovery. Eliminating
							presumptive treatment means avoiding the ill-effects of unnecessary antibiotics and upholding antibiotic stewardship. </p>

						<p className="buttonLink" data-aos="fade-up">
							<ExternalButtonLink 
								href="https://www.visbymedical.com/sexual-health-test/" 
								target="_blank" 
								rel="noopener noreferrer" 
								backgroundcolor="orange"
								aria-label="Learn More about Visby Medical Sexual Health Test">
								Learn More
							</ExternalButtonLink>
						</p>

						<p className="note" data-aos="fade-up">*When expedited partner treatment is relevant.</p>

					</div>
				</div>

				<div className="block block--image">
					<ul>
						<li data-aos="fade-up" key="device1"><span className="line"></span>
							<StaticImage
								src="./../assets/images/devices/device-one.jpg"
								width={300}
								alt="Visby Medical Sexual Health Test device with sample inserted"
								className="side_image"
								style={{ tabindex: -1 }}
							/>
						</li>
						<li data-aos="fade-up" key="device2"><span className="line"></span>
							<StaticImage
								src="./../assets/images/devices/device-two.jpg"
								width={300}
								alt="Hand closing sample area on Visby Medical Sexual Health Test device"
								className="side_image"
								style={{ tabindex: -1 }}
							/>
						</li>
						<li data-aos="fade-up" key="device3"><span className="line"></span>
							<StaticImage
								src="./../assets/images/devices/device-three.jpg"
								width={300}
								alt="Visby Medical Sexual Health Test device showing results"
								className="side_image"
								style={{ tabindex: -1 }}
							/>
						</li>
					</ul>
				</div>

			</StyledBodyLanding>
		</StyledContainer>
	)
}

export default BodyLanding
