import styled, { css } from "styled-components"

// Libraries
import { Link } from "gatsby"

// Utils
import breakpoint from "../utils/breakpoints/"
import { colors } from "../utils/variables/"

export const ButtonLink = styled(Link)`
  min-width: 156px;
  width: 100%;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 18px;
  border-radius: 100px;
  color: ${colors.white};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  box-sizing: border-box;
  transition: all .3s ease;

  ${breakpoint.medium`
    min-width: 196px;
  `}

  ${props =>
    props.backgroundcolor === "primary"
      ? css`
          background-color: ${colors.blue};
          color: ${colors.charcoal};

          &:hover {
            background-color: ${colors.charcoal};
            color: ${colors.white};
          }
        `
      : ``}

  ${props =>
    props.backgroundcolor === "orange"
      ? css`
          background-color: ${colors.orange};
          color: ${colors.charcoal};

          &:hover {
            background-color: ${colors.charcoal};
            color: ${colors.white};
          }
        `
      : ``}

  ${props =>
    props.backgroundcolor === "charcoal"
      ? css`
          background-color: ${colors.charcoal};
            color: ${colors.white};

          &:hover {
            background-color: ${colors.blue};
            color: ${colors.white};
          }
        `
      : ``}

  ${props =>
    props.backgroundcolor === "purpleDark"
      ? css`
          background-color: ${colors.purpleDark};
            color: ${colors.white};

          &:hover {
            background-color: ${colors.charcoal};
            color: ${colors.white};
          }
        `
      : ``}

  ${props =>
    props.backgroundcolor === "transparent"
      ? css`
          background-color: transparent;
          border: 1px solid ${colors.white};
            color: ${colors.white};

          &:hover {
            background-color: ${colors.orange};
            color: ${colors.white};
          }
        `
      : ``}
`

export const ExternalButtonLink = styled.a`
  min-width: 156px;
  width: 100%;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 18px;
  border-radius: 100px;
  color: ${colors.white};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  box-sizing: border-box;
  transition: all .3s ease;

  ${breakpoint.medium`
    min-width: 196px;
  `}

  ${props =>
    props.backgroundcolor === "primary"
      ? css`
          background-color: ${colors.blue};
          color: ${colors.charcoal};

          &:hover {
            background-color: ${colors.charcoal};
            color: ${colors.white};
          }
        `
      : ``}

  ${props =>
    props.backgroundcolor === "orange"
      ? css`
          background-color: ${colors.orange};
          color: ${colors.charcoal};

          &:hover {
            background-color: ${colors.charcoal};
            color: ${colors.white};
          }
        `
      : ``}

  ${props =>
    props.backgroundcolor === "charcoal"
      ? css`
          background-color: ${colors.charcoal};
            color: ${colors.white};

          &:hover {
            background-color: ${colors.blue};
            color: ${colors.white};
          }
        `
      : ``}

  ${props =>
    props.backgroundcolor === "purpleDark"
      ? css`
          background-color: ${colors.purpleDark};
            color: ${colors.white};

          &:hover {
            background-color: ${colors.charcoal};
            color: ${colors.white};
          }
        `
      : ``}
`

export const Button = styled.button`
  min-width: 156px;
  width: 100%;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 18px;
  border-radius: 100px;
  color: ${colors.white};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  box-sizing: border-box;
  transition: all .3s ease;

  ${breakpoint.medium`
    min-width: 196px;
  `}

  ${props =>
    props.backgroundcolor === "primary"
      ? css`
          background-color: ${colors.blue};
          color: ${colors.charcoal};

          &:hover {
            background-color: ${colors.charcoal};
            color: ${colors.white};
          }
        `
      : ``}

  ${props =>
    props.backgroundcolor === "orange"
      ? css`
          background-color: ${colors.orange};
          color: ${colors.charcoal};

          &:hover {
            background-color: ${colors.charcoal};
            color: ${colors.white};
          }
        `
      : ``}

  ${props =>
    props.backgroundcolor === "charcoal"
      ? css`
          background-color: ${colors.charcoal};
            color: ${colors.white};

          &:hover {
            background-color: ${colors.blue};
            color: ${colors.white};
          }
        `
      : ``}

  ${props =>
    props.backgroundcolor === "purpleDark"
      ? css`
          background-color: ${colors.purpleDark};
            color: ${colors.white};

          &:hover {
            background-color: ${colors.charcoal};
            color: ${colors.white};
          }
        `
      : ``}
      
`
